import React from 'react';
import { useStaticQuery, graphql } from 'gatsby';

// Components
import EnLayout from '../../../components/EnLayout';
import EnAboutBanner from '../../../components/EnAboutBanner';

// Images
import Hero from '../../../img/about_03_m_w.jpg';
import MobHero from '../../../img/about_03_m_h.jpg';

import Feature from '../../../components/About/Feature';

// Language
import { getPackOf } from '../../../lang';
const LANG = getPackOf('en');

const AboutFeature = () => {
  const data = useStaticQuery(graphql`
    query {
      markdownRemark(fileAbsolutePath: { glob: "**/src/markdown/about-en/feature.md" }) {
        frontmatter {
          disable
          sectionOne {
            img {
              publicURL
            }
            contents {
              title
              description
            }
          }
          sectionTwo {
            img {
              publicURL
            }
            contents {
              title
              description
            }
          }
          sectionThree {
            img {
              publicURL
            }
            contents {
              title
              description
            }
          }
          sectionFour {
            img {
              publicURL
            }
            contents {
              title
              description
            }
          }
          sectionFive {
            img {
              publicURL
            }
            contents {
              title
              description
            }
          }
        }
      }
    }
  `);

  // Mapping data to fit Feature component
  const convertedData = {
    disable: data.markdownRemark.frontmatter.disable,
    sectionOne: {
      img: data.markdownRemark.frontmatter.sectionOne.img.publicURL,
      contents: data.markdownRemark.frontmatter.sectionOne.contents,
    },
    sectionTwo: {
      img: data.markdownRemark.frontmatter.sectionTwo.img.publicURL,
      contents: data.markdownRemark.frontmatter.sectionTwo.contents,
    },
    sectionThree: {
      img: data.markdownRemark.frontmatter.sectionThree.img.publicURL,
      contents: data.markdownRemark.frontmatter.sectionThree.contents,
    },
    sectionFour: {
      img: data.markdownRemark.frontmatter.sectionFour.img.publicURL,
      contents: data.markdownRemark.frontmatter.sectionFour.contents,
    },
    sectionFive: {
      img: data.markdownRemark.frontmatter.sectionFive.img.publicURL,
      contents: data.markdownRemark.frontmatter.sectionFive.contents,
    },
  };

  return (
    <EnLayout>
      <EnAboutBanner pageTitle={LANG.產品特色} subTitle={LANG.產品特色副標題} bgImg={Hero} mobileBgImg={MobHero} />
      <Feature isPreview={false} data={convertedData} />
    </EnLayout>
  );
};

export default AboutFeature;
