import React from 'react';
import Sec5Bg from '../../img/about_03_bg_1.jpg';

import UnderConstruction from '../UnderConstruction';

const Feature = ({ isPreview, data }) => {
  if (data.disable) {
    return <UnderConstruction />;
  }
  return (
    <>
      <section id="about_03_0" className="py-5 bg-img bg-light">
        <div className="container">
          <div className="row px-3 align-items-center">
            <div className="col-12 col-md-6">
              {data.sectionOne.contents.map((content) => (
                <>
                  <p className="h3">{content.title}</p>
                  <p>{content.description}</p>
                </>
              ))}
            </div>
            <div className="col-12 col-md-6">
              <img src={data.sectionOne.img} alt="" className="img-thumbnail" />
            </div>
          </div>
        </div>
      </section>

      <section id="about_03_1" className="py-5 bg-img">
        <div className="container">
          <div className="row px-3 align-items-center">
            <div className="col-12 col-md-6">
              <img src={data.sectionTwo.img} alt="" className="img-thumbnail" />
            </div>
            <div className="col-12 col-md-6">
              {data.sectionTwo.contents.map((content) => (
                <>
                  <p className="h3">{content.title}</p>
                  <p>{content.description}</p>
                </>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section id="about_03_2" className="py-5 bg-img bg-light">
        <div className="container">
          <div className="row px-3 align-items-center">
            <div className="col-12 col-md-6">
              {data.sectionThree.contents.map((content) => (
                <>
                  <p className="h3">{content.title}</p>
                  <p>{content.description}</p>
                </>
              ))}
            </div>
            <div className="col-12 col-md-6">
              <img src={data.sectionThree.img} alt="" className="img-thumbnail" />
            </div>
          </div>
        </div>
      </section>

      <section id="about_03_3" className="py-5 bg-img">
        <div className="container">
          <div className="row px-3 align-items-center">
            <div className="col-12 col-md-6">
              <img src={data.sectionFour.img} alt="" className="img-thumbnail" />
            </div>
            <div className="col-12 col-md-6">
              {data.sectionFour.contents.map((content) => (
                <>
                  <p className="h3">{content.title}</p>
                  <p>{content.description}</p>
                </>
              ))}
            </div>
          </div>
        </div>
      </section>

      <section id="about_03_4" className="py-5 bg-img bg-light" style={{ backgroundImage: `url(${Sec5Bg})` }}>
        <div className="container">
          <div className="row px-3 align-items-center">
            <div className="col-12 col-md-6">
              {data.sectionFive.contents.map((content) => (
                <>
                  <p className="h3">{content.title}</p>
                  <p>{content.description}</p>
                </>
              ))}
            </div>
            <div className="col-12 col-md-6">
              <img src={data.sectionFive.img} alt="" className="img-thumbnail" />
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Feature;
