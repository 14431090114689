import React from 'react';

import Commingsoon from '../img/comingsoon.jpg';

export default () => (
  <section id="case" class="my-3">
    <div class="container ">
      <div class="row">
        <div class="col-12 col-md-8 py-5 mx-auto">
          <img src={Commingsoon} alt="" />
        </div>
      </div>
    </div>
  </section>
);
