import React from 'react';
import { Helmet } from 'react-helmet';

// Components
import EnNavbar from './EnNavbar';
import EnFooter from './EnFooter';

// Language
import { getPackOf } from '../lang';
const LANG = getPackOf('en');

const Layout = ({ children }) => {
  return (
    <>
      <Helmet>
        <title>{LANG.頁面標題}</title>
      </Helmet>
      <EnNavbar />
      {children}
      <EnFooter />
    </>
  );
};

export default Layout;
